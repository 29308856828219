// tslint:disable:max-classes-per-file
import { WebStorageStateStore } from 'oidc-client-ts';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import store from '../plugins/vuex';
import Services from '@/services/Services';
import * as ConfigModuleDomain from '@/models/ConfigModuleDomain';

@Module({ dynamic: true, store, namespaced: true, name: 'config' })
export default class ConfigModule extends VuexModule {
  public environmentName: string = process.env.NODE_ENV === 'production' ? 'staging' : 'dev';
  public ringName: string = process.env.NODE_ENV === 'production' ? 'user' : 'user';
  public buildName: string = process.env.NODE_ENV === 'production' ? '20250113.404.main' : 'LOCAL';
  public releaseName: string = process.env.NODE_ENV === 'production' ? '' : 'LOCAL';
  // tslint:disable:max-line-length
  public infoSiteUrlNotFound: string = process.env.NODE_ENV === 'production' ? 'https://info-staging.to-do.studio/not-found' : 'http://localhost:8088/not-found';
  public isDevEnvironment: boolean = process.env.NODE_ENV !== 'production';

  public currentServer: ConfigModuleDomain.ServerDefinition;

  public availableServers: ConfigModuleDomain.ServerDefinition[] = [];

  public oidcSettings = {
    popup_redirect_uri: window.location.origin + '/sign-in-popup-callback',
    silent_redirect_uri: window.location.origin + '/sign-in-silent-callback',
    automaticSilentRenew: false, // we manage this in vuexmagic
    validateSubOnSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage, prefix: 'TDS-Auth' }),

    authority: '',
    client_id: '',
    // client_secret: '',
    response_type: 'code',
    scope: 'openid profile offline_access ',
    redirect_uri: window.location.origin + '/sign-in-callback',
    post_logout_redirect_uri: window.location.origin + '/sign-out-callback',
    filterProtocolClaims: true,
    loadUserInfo: false, // workaround because otherwise the library tried to get a userinfo_endpoint from the accessToken
  };

  @Mutation
  public internalInitialize(payload: ConfigModuleDomain.InternalInitializeMutation): void { // eslint-disable-line @typescript-eslint/no-unused-vars
    const envName: string = 'staging';
    if (process.env.NODE_ENV !== 'production' || (process.env.NODE_ENV === 'production' && envName === 'staging')) {
      this.availableServers.push({
        id: 'none',
        name: 'None',
        authorityUrl: '',
        authorityClientId: '',
        apiUrl: '',
        apiScope: '',
      });
    }

    if (process.env.NODE_ENV === 'production') {
      this.availableServers.push({
        id: 'config',
        name: 'staging-user',
        authorityUrl: 'https://todostudiosolutionauth.b2clogin.com/a460df9d-2057-47e1-a6c1-7bb8f4353037/B2C_1_susi/v2.0',
        authorityClientId: '19558437-f0a6-4750-8747-02decc573e69',
        apiUrl: 'https://appapi-staging-user.to-do.studio',
        apiScope: 'https://todostudiosolutionAuth.onmicrosoft.com/81a63a5d-7204-4a34-a54a-d74972e6ce3f/AppAPI',
      });
    } else if (process.env.NODE_ENV !== 'production') {
      this.availableServers.push({
        id: 'dev',
        name: 'Development',
        authorityUrl: 'https://todostudiosolutionAuth.b2clogin.com/a460df9d-2057-47e1-a6c1-7bb8f4353037/B2C_1_susi/v2.0',
        authorityClientId: 'fef0ae00-9367-4ebf-805f-4a302e4c499d',
        apiUrl: 'https://localhost:5001',
        apiScope: 'https://todostudiosolutionAuth.onmicrosoft.com/c6c4367d-93d6-42c1-931f-c2236b1afb04/AppAPI',
      });
      this.availableServers.push({
        id: 'staging-user',
        name: 'Staging',
        authorityUrl: 'https://todostudiosolutionAuth.b2clogin.com/a460df9d-2057-47e1-a6c1-7bb8f4353037/B2C_1_susi/v2.0',
        authorityClientId: '19558437-f0a6-4750-8747-02decc573e69',
        apiUrl: 'https://appapi-staging-user.to-do.studio',
        apiScope: 'https://todostudiosolutionAuth.onmicrosoft.com/81a63a5d-7204-4a34-a54a-d74972e6ce3f/AppAPI',
      });
      this.availableServers.push({
        id: 'prod-user',
        name: 'Production',
        authorityUrl: 'https://todostudiosolutionAuth.b2clogin.com/a460df9d-2057-47e1-a6c1-7bb8f4353037/B2C_1_susi/v2.0',
        authorityClientId: '0d22cc91-e43d-4cb1-84e9-78235a4f1298',
        apiUrl: 'https://appapi-prod-user.to-do.studio',
        apiScope: 'https://todostudiosolutionAuth.onmicrosoft.com/3da048ba-6525-46df-8e15-656b917c5fd9/AppAPI',
      });
    }
  }

  @Action
  public async initialize(payload: ConfigModuleDomain.InitializeAction): Promise<void> { // eslint-disable-line @typescript-eslint/no-unused-vars

    this.context.commit(ConfigModuleDomain.InternalInitializeMutation.MutationName, new ConfigModuleDomain.InternalInitializeMutation());

    let server: ConfigModuleDomain.ServerDefinition | undefined;

    if (this.isDevEnvironment || this.isStagingEnvironment) {
      const ServerOverrideId = await Services.AppPersistantStorage.getItem('ServerEnvironmentOverrideId');
      if (ServerOverrideId) {
        server = this.availableServers.find((t) => t.id === ServerOverrideId);
      }
    }

    // fallback behavior
    if (!server) {
      if (this.isDevEnvironment) {
        server = this.availableServers.find((t) => t.id === 'none');
      } else if (this.isStagingEnvironment) {
        server = this.availableServers.find((t) => t.id === 'config');
      } else if (this.isDemoEnvironment) {
        server = this.availableServers.find((t) => t.id === 'none');
      } else {
        server = this.availableServers.find((t) => t.id === 'config');
      }
    }

    if (!server) {
      throw new Error('no server found');
    }

    this.context.commit(ConfigModuleDomain.InternalSetCurrentServerEnvironmentMutation.MutationName, new ConfigModuleDomain.InternalSetCurrentServerEnvironmentMutation(server));
  }

  @Mutation
  public internalSetCurrentServerEnvironment(payload: ConfigModuleDomain.InternalSetCurrentServerEnvironmentMutation): void {
    this.currentServer = payload.server;
    this.oidcSettings.authority = payload.server.authorityUrl;
    this.oidcSettings.scope = this.oidcSettings.scope + ' ' + payload.server.apiScope;
    this.oidcSettings.client_id = payload.server.authorityClientId;
  }

  @Action
  public async changeServerEnvironment(payload: ConfigModuleDomain.ChangeServerEnvironmentAction): Promise<void> {
    await Services.AppPersistantStorage.setItem('ServerEnvironmentOverrideId', payload.serverEnvironmentId);
  }

  get version(): string {
    return `${this.environmentName} ${this.ringName} ${this.buildName} ${this.releaseName}`.trim();
  }

  get isStagingEnvironment(): boolean {
    if (this.environmentName.toLowerCase() === 'staging') {
      return true;
    } else {
      return false;
    }
  }

  get isDemoEnvironment(): boolean {
    if (this.environmentName.toLowerCase() === 'demo') {
      return true;
    } else {
      return false;
    }
  }

  get isServerConfigured(): boolean {
    if (this.currentServer.id === 'none') {
      return false;
    } else {
      return true;
    }
  }
}
